<!-- Toast -->
<ri-resolution-ui-global-toast></ri-resolution-ui-global-toast>
<!-- Header -->
<div class="relative z-[2]">
  <ri-resolution-ui-app-header></ri-resolution-ui-app-header>
</div>

<!-- Content -->
<div class="relative z-[1]">
  <ri-resolution-ui-network-availability-dialog
    #dialogRef
  ></ri-resolution-ui-network-availability-dialog>
  <!-- If can't reach API or API responds with error so that
   app cannot work without it then dialog (above) is shown.
   This dialog hides everything on the page except header.
   It means we need to prevent elements underneath the backdrop
   to be focusable. The easiest way to do it is to set "inert"
   attribute to the parent node what prevents everything underneath
   it to gain focus. By doing it, only header and dialog itself
   can get focus using keyboard "tab" key -->
  <div [inert]="dialogRef.visible()">
    <router-outlet></router-outlet>
  </div>
</div>
