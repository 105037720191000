import { Injectable, Signal, computed } from '@angular/core';
import { PartialStateUpdater, patchState, signalState } from '@ngrx/signals';
import { convertTaskDtoTo } from '../../../../../shared/utils/tasks.utils';
import { TaskListItemUi } from '../../model/task.model';

interface State {
  data: TaskListItemUi[];
  loading: boolean;
}

const defaultState: State = {
  data: [],
  loading: false,
};

@Injectable({
  providedIn: 'root',
})
export class AllTasksState {
  private readonly tasksState = signalState<State>(defaultState);

  public readonly loading = this.tasksState.loading;

  public readonly tasks: Signal<TaskListItemUi[]> = computed(() => {
    return this.tasksState
      .data()
      .map((task: TaskListItemUi) => convertTaskDtoTo(task));
  });

  setDataState(data: TaskListItemUi[]) {
    patchState(this.tasksState, this.dataSetter(data));
  }

  setLoadingState(isLoading: boolean) {
    patchState(this.tasksState, this.loadingSetter(isLoading));
  }

  private dataSetter(
    data: TaskListItemUi[]
  ): PartialStateUpdater<{ data: TaskListItemUi[] }> {
    return () => ({ data: structuredClone(data) });
  }

  private loadingSetter(
    loading: boolean
  ): PartialStateUpdater<{ loading: boolean }> {
    return () => ({ loading });
  }
}
