import { Injector, inject, runInInjectionContext } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SidebarState } from './sidebar.state';
import { RouteObserver } from '../../../../../shared/services/route-observer/route-observer.service';

export function registerActiveTaskUpdater(
  observer: RouteObserver,
  injector: Injector
) {
  observer.register({
    path: '/tasks/:taskid',
    injector,
    callback: updateSidebarStateCallback,
  });
}

function updateSidebarStateCallback(node: ActivatedRoute, injector: Injector) {
  const sidebarState: SidebarState = runInInjectionContext(injector, () =>
    inject(SidebarState)
  );
  node.params.subscribe((params: Params) => {
    syncSidebarActiveTaskIdWithUrlSegments(params, sidebarState);
  });
}

function syncSidebarActiveTaskIdWithUrlSegments(
  params: Params,
  state: SidebarState
) {
  if (params['taskid']) {
    state.setActiveTaskId(params['taskid']);
  }
}
