import { Injectable, Signal, computed } from '@angular/core';
import { TaskSummaryDto } from '@cloudmed/resolution-services-api-client';
import { PartialStateUpdater, patchState, signalState } from '@ngrx/signals';
import { convertTaskDtoTo } from '../../../../shared/utils/tasks.utils';
import { WorkerTaskItemUi } from '../models/tasks-worker.model';
import { deepEqual } from '../../../../shared/utils/global.utils';

interface State {
  data: TaskSummaryDto[];
  loading: boolean;
}

const defaultState: State = {
  data: [],
  loading: false,
};

@Injectable()
export class TasksWorkerListBaseState {
  private readonly tasksState = signalState<State>(defaultState);

  public readonly loading = this.tasksState.loading;

  public readonly tasks: Signal<WorkerTaskItemUi[]> = computed(
    () => {
      return this.tasksState
        .data()
        .map((task: TaskSummaryDto) => convertTaskDtoTo(task));
    },
    {
      equal: (a: WorkerTaskItemUi[], b: WorkerTaskItemUi[]) => {
        return deepEqual(a, b);
      },
    }
  );

  public setDataState(data: TaskSummaryDto[]) {
    patchState(this.tasksState, this.dataSetter(data));
  }

  public setLoadingState(isLoading: boolean) {
    patchState(this.tasksState, this.loadingSetter(isLoading));
  }

  private dataSetter(
    data: TaskSummaryDto[]
  ): PartialStateUpdater<{ data: TaskSummaryDto[] }> {
    return () => ({ data: structuredClone(data) });
  }

  private loadingSetter(
    loading: boolean
  ): PartialStateUpdater<{ loading: boolean }> {
    return () => ({ loading });
  }
}
