import { Injectable } from '@angular/core';
import { PartialStateUpdater, patchState, signalState } from '@ngrx/signals';
import { Tabs } from '../../models/sidebar.model';

interface State {
  activeTab: Tabs;
  collapsed: boolean;
  searchString: string;
  activeTaskId: string;
}

const defaultState: State = {
  activeTab: Tabs.todo,
  collapsed: false,
  searchString: '',
  activeTaskId: '',
};

@Injectable({
  providedIn: 'root',
})
export class SidebarState {
  private readonly sidebarState = signalState<State>(defaultState);

  public readonly searchString = this.sidebarState.searchString;
  public readonly activeTab = this.sidebarState.activeTab;
  public readonly collapsed = this.sidebarState.collapsed;
  public readonly activeTaskId = this.sidebarState.activeTaskId;

  setActiveTab(activeTab: Tabs): void {
    patchState(this.sidebarState, this.activeTabSetter(activeTab));
  }

  setCollapsed(isCollapsed: boolean): void {
    patchState(this.sidebarState, this.collapsedSetter(isCollapsed));
  }

  setSearchString(searchString: string): void {
    patchState(this.sidebarState, this.searchStringSetter(searchString));
  }

  setActiveTaskId(activeTaskId: string) {
    patchState(this.sidebarState, this.activeTaskIdSetter(activeTaskId));
  }

  private activeTabSetter(
    activeTab: number
  ): PartialStateUpdater<{ activeTab: Tabs }> {
    return () => ({ activeTab });
  }

  private collapsedSetter(
    collapsed: boolean
  ): PartialStateUpdater<{ collapsed: boolean }> {
    return () => ({ collapsed });
  }

  private searchStringSetter(
    searchString: string
  ): PartialStateUpdater<{ searchString: string }> {
    return () => ({ searchString });
  }

  private activeTaskIdSetter(
    activeTaskId: string
  ): PartialStateUpdater<{ activeTaskId: string }> {
    return () => ({ activeTaskId });
  }
}
