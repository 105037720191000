import {
  LimitedResultOfTaskSummaryDto,
  TaskSummaryDto,
  TasksClient,
} from '@cloudmed/resolution-services-api-client';
import { filter, finalize, map } from 'rxjs';
import { AllTasksState } from './all-tasks.state';
import { Injectable } from '@angular/core';
import {
  loadCursorBasedApi,
  updateData,
} from 'src/app/shared/utils/global.utils';
import { GetTasksParams } from 'src/app/shared/models/tasks.model';
import { convertToGetTasksParams } from 'src/app/shared/utils/tasks.utils';

/**
 * Currently for getting list of all tasks we are
 * uisng the same API as for getting My Tasks.
 * It will be changed at some point.
 */
@Injectable({
  providedIn: 'root',
})
export class AllTasksEffect {
  constructor(
    private readonly tasksClient: TasksClient,
    private readonly state: AllTasksState
  ) {}

  loadTasks(): void {
    this.state.setLoadingState(true);
    const allItems: TaskSummaryDto[] = [];
    loadCursorBasedApi<LimitedResultOfTaskSummaryDto, GetTasksParams>(
      (params: GetTasksParams) => {
        return this.tasksClient.getTasks(...convertToGetTasksParams(params));
      },
      {}
    )
      .pipe(
        finalize(() => {
          this.state.setDataState(allItems);
          this.state.setLoadingState(false);
        })
      )
      .subscribe((response: LimitedResultOfTaskSummaryDto) => {
        allItems.push(...(response.items || []));
      });
  }

  mergeTasks(params: GetTasksParams): void {
    this.tasksClient
      .getTasks(...convertToGetTasksParams(params))
      .pipe(
        filter((response: LimitedResultOfTaskSummaryDto) => !!response.items),
        map((response: LimitedResultOfTaskSummaryDto) => response.items!)
      )
      .subscribe((newTasks: TaskSummaryDto[]) => {
        const currentTasks = [...this.state.tasks()];
        updateData(currentTasks, newTasks, 'id', 'update-only');
        this.state.setDataState(currentTasks);
      });
  }
}
