<p-dialog
  [(visible)]="visible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="false"
  [focusTrap]="false"
  [closable]="false"
  [style]="{ 'inline-size': '70vw' }"
>
  <div class="max-h-[70vh] overflow-auto [scrollbar-width:thin]">
    <p class="mb-4">
      We are unable to connect to critical services. Please ensure that Netskope
      is enabled (if installed) and refresh the page. If the issue persists,
      please report the issue navigating to this
      <a [href]="reportIssueUrl" alt="report network issue">link</a>
    </p>
    <p class="mb-4"><strong>Browser:</strong> {{ userAgent }}</p>
    <p class="mb-4"><strong>Timestamp:</strong> {{ utcDate() }}</p>
    <p class="mb-4">
      <strong>User:</strong> {{ userData()?.name }} {{ userData()?.email }}
    </p>
    <ul class="m-0 list-none p-0">
      @for (err of errors(); track err) {
        <li>
          <p class="mb-4">
            <strong>{{ err.api }}:</strong>
          </p>
          <pre>{{ err.err | json }}</pre>
        </li>
      }
    </ul>
  </div>
</p-dialog>
