import {
  LimitedResultOfTaskSummaryDto,
  TaskSummaryDto,
  TasksClient,
} from '@cloudmed/resolution-services-api-client';
import { finalize } from 'rxjs';
import { TasksWorkerListBaseState } from '../state/tasks-worker-list-base.state';
import { GetTasksParams } from 'src/app/shared/models/tasks.model';
import { convertToGetTasksParams } from 'src/app/shared/utils/tasks.utils';
import { loadCursorBasedApi } from 'src/app/shared/utils/global.utils';

export abstract class TasksWorkerListBaseEffect {
  protected abstract getTasksArgs: GetTasksParams;
  constructor(
    protected readonly tasksClient: TasksClient,
    protected readonly state: TasksWorkerListBaseState
  ) {}

  loadTasks(): void {
    this.state.setLoadingState(true);
    const allItems: TaskSummaryDto[] = [];
    loadCursorBasedApi<LimitedResultOfTaskSummaryDto, GetTasksParams>(
      (params: GetTasksParams) => {
        return this.tasksClient.getTasks(...convertToGetTasksParams(params));
      },
      this.getTasksArgs
    )
      .pipe(
        finalize(() => {
          this.state.setDataState(allItems);
          this.state.setLoadingState(false);
        })
      )
      .subscribe((response: LimitedResultOfTaskSummaryDto) => {
        allItems.push(...(response.items || []));
      });
  }
}
