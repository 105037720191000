import { Injectable, Injector, signal } from '@angular/core';
import { apiAvailabilityFunctions, ApiNames } from '../settings/settings';
import { catchError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class NetworkAvailabilityHandlerService {
  private readonly _errorSignal = signal<
    {
      api: ApiNames;
      serverNotRespond: boolean;
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      err: any;
    }[]
  >([]);

  readonly errorSignal = this._errorSignal.asReadonly();

  constructor(private readonly injector: Injector) {}

  checkAvailability(apis: ApiNames[]): void {
    this._errorSignal.set([]);

    for (const apiName of Object.values(apis)) {
      const func = apiAvailabilityFunctions[apiName];

      func
        .call(this, this.injector)
        .pipe(
          catchError((err) => {
            this._errorSignal.update((value) => {
              return [
                ...value,
                {
                  api: apiName,
                  serverNotRespond: !(err instanceof HttpErrorResponse),
                  err,
                },
              ];
            });

            return of(err);
          })
        )
        .subscribe();
    }
  }
}
