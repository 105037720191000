import { Injectable } from '@angular/core';
import { TasksWorkerTodoListState } from './tasks-worker-todo-list.state';
import {
  TaskItemStatus,
  TasksClient,
} from '@cloudmed/resolution-services-api-client';
import { TasksWorkerListBaseEffect } from '../tasks-worker-list-base.effect';
import { SORT_BY } from '../../models/tasks-worker-todo.model';
import { GetTasksParams } from 'src/app/shared/models/tasks.model';

@Injectable({
  providedIn: 'root',
})
export class TasksWorkerTodoListEffect extends TasksWorkerListBaseEffect {
  readonly getTasksArgs: GetTasksParams = {
    status: [TaskItemStatus.Pending],
    sort: SORT_BY,
  };

  constructor(
    public override readonly tasksClient: TasksClient,
    public override readonly state: TasksWorkerTodoListState
  ) {
    super(tasksClient, state);
  }
}
