/* eslint-disable no-use-before-define */
import { TaskSummaryDto } from '@cloudmed/resolution-services-api-client';
import { GetTasksParams, GetTasksParamsOriginal } from '../models/tasks.model';

export function convertTaskDtoTo<T extends { patientName: string }>(
  task: TaskSummaryDto
): T {
  return {
    ...task,
    patientName: getPatientName(task),
  } as T;
}

function getPatientName(task: TaskSummaryDto): string {
  return `${task.patientLastName} ${task.patientFirstName}`;
}

export function convertToGetTasksParams(
  params: GetTasksParams
): GetTasksParamsOriginal {
  return [
    params.limit,
    params.continuationToken,
    params.status,
    params.assignedTo,
    params.completedBy,
    params.sort,
    params.completedSince,
  ];
}
