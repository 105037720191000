import { HttpClient } from '@angular/common/http';
import { inject, Injector, runInInjectionContext } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/shared/app-config/app-config.service';

export enum ApiNames {
  MechanicalTurk = 'MechanicalTurk',
  AccountLink = 'AccountLink',
  ResolutionStore = 'ResolutionStore',
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type ApiAvialabilityChecker = (injector: Injector) => Observable<any>;

export const apiAvailabilityFunctions: {
  [key in ApiNames]: ApiAvialabilityChecker;
} = {
  [ApiNames.MechanicalTurk]: (injector: Injector) => {
    return runInInjectionContext(injector, () => {
      const httpClient = inject(HttpClient);
      const appConfigService = inject(AppConfigService);
      return httpClient.get(
        appConfigService.config.healthCheckUrls.resolutionApi,
        { responseType: 'text' }
      );
    });
  },
  [ApiNames.AccountLink]: (injector: Injector) => {
    return runInInjectionContext(injector, () => {
      const httpClient = inject(HttpClient);
      const appConfigService = inject(AppConfigService);
      return httpClient.get(
        appConfigService.config.healthCheckUrls.accountLink,
        { responseType: 'text' }
      );
    });
  },
  [ApiNames.ResolutionStore]: (injector: Injector) => {
    return runInInjectionContext(injector, () => {
      const httpClient = inject(HttpClient);
      const appConfigService = inject(AppConfigService);
      return httpClient.get(
        appConfigService.config.healthCheckUrls.resolutionStore,
        { responseType: 'text' }
      );
    });
  },
};

export const apiAvailabilityErrorMessages = {
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  noResponseMessage: (err?: string) =>
    `It looks like some of our services are currently unavailable.
     Please wait a few minutes and refresh your page.
     If the issue persists, please contact support at: TBD@TBD.TBD`,
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  serverRespondWithError: (err?: string) =>
    `We are unable to connect to critical services.
     Please ensure that Netskope is enabled (if installed) and refresh the page.
     If the issue persists, please contact support at: TBD@TBD.TBD`,
};
