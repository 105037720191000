import { Injectable } from '@angular/core';
import {
  TaskItemStatus,
  TasksClient,
} from '@cloudmed/resolution-services-api-client';
import { TasksWorkerCompletedListState } from './tasks-worker-completed-list.state';
import { TasksWorkerListBaseEffect } from '../tasks-worker-list-base.effect';
import {
  COMPLETED_SINCE_IN_DAYS_BOUNDARY,
  SORT_BY,
} from '../../models/tasks-worker-completed.model';
import { GetTasksParams } from 'src/app/shared/models/tasks.model';

@Injectable({
  providedIn: 'root',
})
export class TasksWorkerCompletedListEffect extends TasksWorkerListBaseEffect {
  readonly getTasksArgs: GetTasksParams = {
    status: [TaskItemStatus.Completed],
    sort: SORT_BY,
    completedSince: new Date(
      Date.now() - COMPLETED_SINCE_IN_DAYS_BOUNDARY * 24 * 60 * 60 * 1000
    ),
  };

  constructor(
    public override readonly tasksClient: TasksClient,
    public override readonly state: TasksWorkerCompletedListState
  ) {
    super(tasksClient, state);
  }
}
