import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
} from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { RESOLUTION_SERVICES_API_BASE_URL } from '@cloudmed/resolution-services-api-client';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { MessageService } from 'primeng/api';
import { appRoutes } from './app.routes';
import { initializeAppConfig } from './shared/app-config/app-config.factory';
import { AppConfigService } from './shared/app-config/app-config.service';
import { InsightsService } from './shared/services/insights/insights.service';
import { SHELL_HOME_CONFIG_RI, ShellHomeConfigRi } from '@cloudmed/ui';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { APOLLO_NAMED_OPTIONS, Apollo, NamedOptions } from 'apollo-angular';
import { FILE_SHED_API_BASE_URL } from '@cloudmed/file-shed-angular-client';

export const appConfig: ApplicationConfig = {
  providers: [
    MessageService,
    provideAnimationsAsync(),
    provideRouter(appRoutes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    provideAuth0(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppConfig,
      deps: [AppConfigService],
      multi: true,
    },
    InsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    {
      provide: RESOLUTION_SERVICES_API_BASE_URL,
      useFactory: (appConfigService: AppConfigService) => {
        return appConfigService.config.resolutionServiceBaseUrl;
      },
      deps: [AppConfigService],
    },
    {
      provide: FILE_SHED_API_BASE_URL,
      useFactory: (appConfigService: AppConfigService) => {
        return appConfigService.config.fileshedServiceBaseUrl;
      },
      deps: [AppConfigService],
    },
    {
      provide: SHELL_HOME_CONFIG_RI,
      useFactory: (appConfigService: AppConfigService) => {
        return {
          isLocal: appConfigService.config.environment === 'local',
          riUserManagementApiUrl: appConfigService.config.riUserManagementUrl,
        } as ShellHomeConfigRi;
      },
      deps: [AppConfigService],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(
        httpLink: HttpLink,
        appConfigService: AppConfigService
      ): NamedOptions {
        return {
          // Resolution Store Client
          resolutionStoreClient: {
            link: ApolloLink.from([
              httpLink.create({
                uri: appConfigService.config.resolutionStoreGraphQlUrl,
              }),
            ]),
            cache: new InMemoryCache({
              addTypename: false,
            }),
            defaultOptions: {
              watchQuery: {
                fetchPolicy: 'no-cache',
              },
              query: {
                fetchPolicy: 'no-cache',
              },
            },
          },
          // Account Link Client
          accountLinkClient: {
            link: ApolloLink.from([
              httpLink.create({
                uri: appConfigService.config.accountLinkGraphQlUrl,
              }),
            ]),
            cache: new InMemoryCache({
              addTypename: false,
            }),
            defaultOptions: {
              watchQuery: {
                fetchPolicy: 'no-cache',
              },
              query: {
                fetchPolicy: 'no-cache',
              },
            },
          },
        };
      },
      deps: [HttpLink, AppConfigService],
    },
    Apollo,
  ],
};
